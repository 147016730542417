import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateSettings } from "../../actions/stakeAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import  Web3 from 'web3';

import 'react-toastify/dist/ReactToastify.css';
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false, 
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class SettingUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record._id,
           
            Heading:this.props.record.Heading,
            Description: this.props.record.Description,
            errors: {},
           };
    }
    componentDidMount(props){
        console.log("data :",this.props.record)
       
    }
   

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
               
               Heading:nextProps.record.Heading,
               Description: nextProps.record.Description,
            })
          
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
            // $('#update-stake-modal').modal('show');
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {
           
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        $('#update-stake-modal').modal('hide');
    }
   
    onChange = e => {
       
     if (e.target.id === 'Heading') {
          this.setState({ Heading: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }
       

        

    };
    onFileChange = event => {
        var name = event.target.value;
           // Update the state
           this.setState({ file: event.target.files[0]});
           this.setState({ imagepath: name});
         
         };
   
    onStakeUpdate = e => {
        e.preventDefault();
        const updateStake = {
            _id: this.state.id,
            
            Heading:this.state.Heading,
            Description:this.state.Description,
 
        };
        this.props.updateSettings(updateStake);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-stake-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Roadmap</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate  id="update-stake">
                                   
                                         <div className="row mt-2">

                                        <div className="col-md-3">
                                            <label htmlFor="alloc">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading}
                                                placeholder="Multipier (x)"
                                                id="Heading"
                                                type="text"
                                                required
                                                readOnly
                                               
                                                error={errors.Heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}/>
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                       
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenSymbol">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                id="Description"
                                                type="text"
                                                required
                                               
                                                error={errors.Description}
                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}/>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>
                                    </div>
                                   
                                   
                                    

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="update-stake"
                                   
                                    className="btn btn-primary"  onClick={this.onStakeUpdate}>
                                    Update Roadmap
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SettingUpdate.propTypes = {
    updateStake: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateSettings }
)(withRouter(SettingUpdate));
