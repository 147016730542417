import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import {Form,InputGroup,Button } from 'react-bootstrap'
import $, { error } from 'jquery';

import 'react-toastify/dist/ReactToastify.css';
import { addblocklistuser, checkaddress } from '../web3helper/locketstakinghelper';

class Addblocklist extends React.Component {

    constructor() {
        super();
        this.state = {
            name: "",
            email: "",
            password: "",
            password2: "",
            errors: {},
            show1 : false,
            show: false,
            error : "",
            account : localStorage?.getItem("userwallet") ? localStorage?.getItem("userwallet") : ""
        };
    }
  
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined) {
            $('#add-blocklist').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

  

    onChange = async(e) => {
        this.setState({ [e.target.id]: e.target.value });
        if(e?.target?.value?.length >= 42){
            var valid = await checkaddress(e?.target?.value);
            if(!valid){
                this.setState({error : "Invalid Address!"})
            }
            else{
                this.setState({error : ""})
            }
        }

    };

    onUserAdd = (e) => {
        // e.preventDefault();
        // const newUser = {
        //     name: this.state.name,
        //     email: this.state.email,
        //     password: this.state.password,
        //     password2: this.state.password2
        // };
        // this.props.addUser(newUser, this.props.history);
        if(this.props.wallet){
            console.log("add block list" , this.props);
            var result =  addblocklistuser(this?.state?.name , this.props.wallet);
            console.log("result in add block user" , result);
            $('#add-blocklist').modal('hide');
        }
        else{
            toast("Please connect your wallet")
        }
        
    };

    addblocklist = async() => {
        if(this.props.wallet){
            console.log("add block list" , this.props);
            var result =  await addblocklistuser(this?.state?.name , this.props.wallet);
            console.log("result in add block user" , result);
            this.props.refresh();
        }
        else{
            toast("Please connect your wallet")
        }
    }

    showPassword = (e) => {

        var e = document.getElementById("password");
        
        if (e.type === "password") {
           
            this.setState({ show1: true });
            e.type = "text";
        } else {
            this.setState({ show1: false });
            e.type = "password";
        }
    };
    showPassword1 = (e) => {

        var e = document.getElementById("password2");
        
        if (e.type === "password") {
           
            this.setState({ show: true });
            e.type = "text";
        } else {
            this.setState({ show: false });
            e.type = "password";
        }
    };
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-blocklist" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
            {console.log("userwallet" ,this.props) }

                            <div className="modal-header">
                                <h4 className="modal-title">Add Blocklist</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate  id="add-blocklist"> 
                                {/* onSubmit={this.onUserAdd} */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Wallet Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: this.state.error
                                                })}/>
                                            <span className="text-danger">{this.state?.error}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                error={errors.email}
                                                id="email"
                                                type="email"
                                                className={classnames("form-control", {
                                                    invalid: errors.email
                                                })}
                                            />
                                            <span className="text-danger">{errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password">Password</label>
                                        </div>
                                        <InputGroup className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.password}
                                                error={errors.password}
                                                id="password"
                                                type="password"
                                                className={classnames("form-control", {
                                                    invalid: errors.password
                                                })}
                                            />
                                            <InputGroup.Text >
                                    <span className="fa fa-eye" onClick={this.showPassword}></span>
                                        
                                    </InputGroup.Text>
                                            <span className="text-danger">{errors.password}</span>
                                        </InputGroup>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Confirm Password</label>
                                        </div>
                                        <InputGroup className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.password2}
                                                id="password2"
                                                type="password"
                                                className={classnames("form-control", {
                                                    invalid: errors.password2
                                                })}
                                            />
                                              <InputGroup.Text >
                                    {!this.show ? <span className="fa fa-eye" onClick={this.showPassword1}></span>:
                                        <span className="fa fa-eye" onClick={this.showPassword1}></span>}
                                    </InputGroup.Text>
                                            <span className="text-danger">{errors.password2}</span>
                                        </InputGroup>
                                    </div> */}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-blocklist"
                                    type="submit"
                                    className="btn btn-primary" onClick={this.addblocklist}>
                                    Add 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Addblocklist.propTypes = {
    addUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(Addblocklist));
