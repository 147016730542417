import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateterminal } from "../../actions/formActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Web3 from 'web3';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CKEditor } from 'ckeditor4-react';
import Developterminal from '../pages/Developterminal';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))


let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
}
class Developterminalupdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record._id,
            Title: this.props.record.identifier,
            // Heading: this.props.record.Heading,
            yieldDescription: this.props.record.content,
            // NewsLink: this.props.record.NewsLink,
            file: {},
            errors: {},
        };
    }
    componentDidMount(props) {
        console.log("data>>>>>>> :", this.props)

    }


    componentWillReceiveProps(nextProps) {
        console.log("next Props>>>>",nextProps)
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                Title: nextProps.record.identifier,
                // Heading: nextProps.record.Heading,
                Description: nextProps.record.content,
                // NewsLink: nextProps.record.NewsLink,
                // file: nextProps.record.file,
            })
            // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {

            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        $('#update-newsdes-modal').modal('hide');

    }

    onChange = e => {


        if (e.target.id === 'Title') {
            this.setState({ Title: e.target.value });
        }
        if (e.target.id === 'Heading') {
            this.setState({ Heading: e.target.value });
        }
        if (e.target.id === 'Description') {
            this.setState({ Description: e.target.value });
        }
        if (e.target.id === 'NewsLink') {
            this.setState({ NewsLink: e.target.value });
        }




    };


    handleChange = (value) => {
        this.setState({ Description: value });
    }
    onFileChange = event => {
        var name = event.target.value;
        // Update the state
        this.setState({ file: event.target.files[0] });
        this.setState({ imagepath: name });

    };


    // onFileChange = async(event) => {
    //     var name = event.target.value;
    //     this.setState({ file: event.target.files[0]});
    //     this.setState({ imagepath: name});
    //        var width;
    //        var height;
    //        var img = new Image()
    //        img.src = window.URL.createObjectURL(event.target.files[0])
    //        img.onload = () => {
    //         width = img.width;
    //         height = img.height;



    //        if ( Number(width) != Number(1600) && Number(height) != Number(675)){
    //         toast.error(`Image Height and width should be ${675} pixel and ${1600} pixel`,{
    //             style: {
    //             minWidth: '300px',
    //             minHeight: '55px'
    //             }
    //         }) 

    //         this.setState({ file: ""});
    //               this.setState({ imagepath: ""});

    //       }

    //         sleep(2000) 


    //        }
    //      };
    handleEditorChange = e => {
        this.setState({ Description: e.editor?.getData() });
    };
 

    onFormUpdate = e => {
        e.preventDefault();
        const updateForm = {
            _id: this.state.id,
            Title: this.state.Title,
            // Heading: this.state.Heading,
            Description: this.state.Description,
            // NewsLink: this.state.NewsLink,
            // file: this.state.file

        };
        console.log("updateForm",updateForm);
        this.props.updateterminal(updateForm);
    };


   
    render() {
        const { errors } = this.state;
        console.log("hhjkhjkhjkh",this.state.Description);
        return (
            <div>
                <div className="modal fade" id="update-newsdes-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Terminal</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate id="update-form">
                                    {/* <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="form-update-id"
                                        type="text"
                                        required
                                        className="d-none" /> */}

                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="tokenAddresses">Title</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Title}
                                                error={errors.Title}
                                                id="Title"
                                                type="text"
                                                required
                                                className={classnames("form-control", {
                                                    invalid: errors.Title
                                                })}
                                            />
                                            <span className="text-danger">{errors.Title}</span>
                                        </div>
                                    </div> */}

                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="depositFee">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                          {this.state.Description && <CKEditor 
                                        onChange={this.handleEditorChange}
                                          initData={this.state.Description}
                                          id="Description"
                                          />}
                                            <ReactQuill
                                                onChange={this.handleChange}
                                                value={this.state.Description}
                                                error={errors.Description}
                                                id="Description"
                                                theme="snow"
                                                type="text"
                                            />
                                             <span className="text-danger">{errors.Description}</span>
                                        </div>


                                    </div>



                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <a
                                    form="update-form"

                                    className="btn btn-primary" onClick={this.onFormUpdate}>
                                    Update
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Developterminalupdate.propTypes = {
    updateForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateterminal }
)(withRouter(Developterminalupdate));
