import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addIpaddress } from "../../actions/ipAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';


import 'react-toastify/dist/ReactToastify.css';



class IpaddressAddModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            whitelist: "",
             errors: {},
};
    }
   

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined) {
            $('#add-ipaddress-modal').modal('hide');
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

   
    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
   
    onIpaddressAdd = e => {
        e.preventDefault();
        const newIp = {
            whitelist: this.state.whitelist,
            
        };
        
        this.props.addIpaddress(newIp, this.props.history);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-ipaddress-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add IP Address</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onIpaddressAdd} id="add-ip">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Enter IP Address</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.whitelist}
                                                placeholder="Enter IP Address"
                                                id="whitelist"
                                                type="text"
                                                error={errors.whitelist}
                                                className={classnames("form-control", {
                                                    invalid: errors.whitelist
                                                })}/>
                                            <span className="text-danger">{errors.whitelist}</span>
                                        </div>
                                    </div>
                                    </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-ip"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add Ip Address
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

IpaddressAddModal.propTypes = {
    addIpaddress: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addIpaddress }
)(withRouter(IpaddressAddModal));
