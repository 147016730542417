import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import LaunchPadAddModal from "../partials/LaunchpadAddModal";
// import StakeUpdateModal from "../partials/StakeUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import backurl  from "../../lib/config";
import Addblocklistmodal from "../partials/addblocklistmodal";
import { removeblocklistuser } from "../web3helper/locketstakinghelper";
import { Modal } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";

class Blocklist extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "walletaddress",
                text: "Wallet Address",
                className: "id",
                align: "left",
                //   cell: record =>  {
                //     return    (
                //        <p>{record.pid + 1}</p>
                //       )}
            },
          
        //  {
        //         key:"PresaleAddress",
        //         text: "Presale Address",
        //         className: "PresaleAddress",
        //         align: "left",
               
        //     },
          
           
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    console.log("record.PresaleAddress",record.PresaleAddress);
                    return (
                        <Fragment>
                        {/* <Link to={`/ManageSale/${record.PresaleAddress}`}
                        
                                //data-toggle="modal"
                                //data-target="#update-stake-modal"
                                className="btn btn-primary btn-sm"
                                //onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-edit"></i>
                            </Link> */}
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Pools",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "<<",
                    previous: "<",
                    next: ">",
                    last: ">>"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
            PriceToken:0,
            TokenAddress:"",
            TokenName:0,
            TokenDecimal:0,
            MinimumContribution:0,
            MaximumContribution:0,
            NumberDaySale: 0,
            DepositeToken:0,
            HardCap:0,
            PresaleAddress:0,
            WhiteList:[{ user: "", bnbvalue: "" }],
            },
            addmodal : false,
            user : localStorage?.getItem("userwallet") ? localStorage?.getItem("userwallet") : "",
            show: false
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    MetaMask = async (e) => {
        try{
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                this.setState({ web3state: web3 })
                try {
                    window.ethereum.enable().then(async () => {
                        // User has allowed account access to DApp...
                        const accounts = await web3.eth.getAccounts();
                        console.log("Account : ", accounts[0]);
                        const data = accounts[0];
    
                        this.setState({ user: data });
                        localStorage.setItem("userwallet" , data);
                        this.setState({show : false})
                        
                    });
                } catch (e) {
                    // User has denied account access to DApp...
                }
            }
            // Legacy DApp Browsers
            else if (window.web3) {
                const web3 = new Web3(window.web3.currentProvider);
                this.setState({ web3state: web3 })
            }
            // Non-DApp Browsers
            else {
                //alert('No Dapp Supported Wallet Found');
                console.log("No Dapp Supported Wallet Found")
            }
        }catch(e){

        }
       
    }

    enableWallet = async (e) => {

        //Create WalletConnect Provider
        console.log("Wallet connect");
        // const provider = new WalletConnectProvider({
        //     rpc: {
        //         56: "https://bsc-dataseed.binance.org",
        //         // 56:"https://data-seed-prebsc-2-s1.binance.org:8545/"
        //         // ...
        //     },
        // }
        // );
        const provider = new WalletConnectProvider({
            rpc: {
                 56: "https://bsc-dataseed1.ninicoin.io",
                // 97: "https://data-seed-prebsc-1-s1.binance.org:8545",
                // ...
            },
            network: 'binance',
            chainId: 56,
            // infuraId: "69de03b5c7194095980c9233f9cf71df",
        }
        );
        await provider.enable().then(function (error, result) {
            console.log('error: ' + error);
            console.log(result);

        })
            .catch(e => {
                //try again
            });
            // await this.getAdminDetails();
            const web3 = new Web3(provider);
            this.setState({ web3state: web3 })
                    }

    getData() {
        axios
            .post(backurl+"/api/get-blocklist")
            .then(res => {console.log(res , "data");
            if(res?.data?.type == "success"){
                this.setState({ records: res.data?.data})
            }
                
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    async deleteRecord(record) {
        var result = await removeblocklistuser(record?.walletaddress , this?.state?.user);
        
        // axios
        //     .post(backurl+"/api/LaunchPad-delete", {_id: record._id})
        //     .then(res => {
        //         if (res.status === 200) {
        //            toast(res.data.message, {
        //                position: toast.POSITION.TOP_CENTER,
        //            })
        //         }
        //     })
        //     .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Modal className="wallet-modal " show={this.state.show}>
                        <Modal.Header className="pb-0">
                            <div> 
                                <h3 className="sec-head ">Select a Wallet</h3>
                                <p>Select your preferred wallet for payment</p>
                            </div>
                            <button type="button" class="close" onClick={() => this.setState({ show: false })}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

                        </Modal.Header>
                        <Modal.Body className="select-wallet text-center pt-0">

                            <div className="wallet-lists">
                                <ul>
                                    <li onClick={this.MetaMask}>
                                        
                                        <div className="wal-option">
                                            <h3 className="side-head mb-0"> Metamask</h3>

                                        </div>
                                    </li>
                                    
                                </ul>
                                <ul>
                                    <li onClick={this.enableWallet}>
                                        
                                        <div className="wal-option">
                                            <h3 className="side-head mb-0">Wallet Connect</h3>

                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </Modal.Body>
                    </Modal>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    {/* <LaunchPadAddModal/> */}
                    <Addblocklistmodal wallet = {this?.state?.user} refresh = {this.getData}/>
                    <div className="row">
                           
 
                                <div className="col-12 col-lg-6 right_sec_align">
                                <div className="presale-Address">
                                </div>
                                    </div>
                            </div>
                    {/* <StakeUpdateModal record={this.state.currentRecord}/> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                           <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-blocklist"><FontAwesomeIcon icon={faPlus}
                            // onClick={()=>this.setState({addmodal:true})}
                            /> Add </button>
                            <h1 className="mt-2 text-primary">BlockList</h1>
                            <p className="label_big_1 text-white">Wallet Address: </p>
                                    <a href="#" className="btn btn-outline-primary mb-0  mt-0 cont-btn" onClick={() => this.state.user === "" ? this.setState({ show: true }) : this.Disconnect()}>{this.state.user === "" ? "Connect wallet" : this.state.user}</a>
                            <div className="responsive-table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            /></div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Blocklist.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Blocklist);
