import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import FormAddModal from "../partials/FormAddModal";
import HomeUpdateModal from "../partials/HomeUpdateModal"
import { toast, ToastContainer} from "react-toastify";
import backurl  from "../../lib/config";

class Forms extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                // key: "_id",
                text: "S.No",
                className: "id",
                align: "left",
                sortable: true,
                cell: 
                    (record,index) => index+1
            },
                

        //     {
        //         key: "logoURI",
        //         text: "Image",
        //         className: "logoURI",
        //         align: "left",
        //         sortable: true,
        //         cell: record =>  {
        //         return    (
        //             <img
        //               src={backurl+ "/Images/"+record.logoURI}
        //             // src={"http://localhost:2053/Images/"+record.logoURI}
        //               width={50}
        //               alt='form'
        //             />
        //           )}
        //     },
        {
            key:"email",
            text: "Email",
            className: "Tittle",
            align: "left",
            sortable: true,
        },
            // {
            //     key:"Title",
            //     text: "Title",
            //     className: "Tittle",
            //     align: "left",
            //     sortable: true,
               
            // },
            // {
            //     key: "Heading",
            //     text: "Heading",
            //     className: "Heading",
            //     align: "left",
            //     sortable: true
            // },
            // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: record => {
            //         return (
            //             <Fragment>
            //                 <button
            //                     data-toggle="modal"
            //                     data-target="#update-form-modal"
            //                     className="btn btn-primary btn-sm"
            //                     onClick={() => {this.setState({show: true});this.editRecord(record)}}
            //                     style={{marginRight: '5px'}}>
            //                     <i className="fa fa-edit"></i>
            //                 </button>
            //                { record?.Status == "true" ? 
            //                <>
            //                <button  className="btn btn-danger btn-sm" onClick={() => this.editStatus(record)}>  <i className="fa fa-eye"></i> </button>
            //                </> : <>
            //                <button  className="btn btn-danger btn-sm" onClick={() => this.editStatus(record)}>  <i className="fa fa-eye-slash"></i> </button>
            //                </>}
            //             </Fragment>
            //         );
            //     }
            // },
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Forms",
            no_data_text: 'No user found!',
            button: {
                excel: true,
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ ",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "<<",
                    previous: "<",
                    next: ">",
                    last: ">>"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id:"",
                Title:"",
                Heading:"",
                Description:"",
                file:{},
            },
            show : false
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData()
    };

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    getData() {
        console.log("ajkdsd")
        axios
            .get(backurl+"/api/get/subscribers")
            .then(res => {
                console.log("Ressssssssssss", res)
                this.setState({ records: res.data.data})
               
            })
            .catch()

    }

    editRecord(record) {
        console.log("vavava11",record)
        this.setState({ currentRecord: record});
    }

    editStatus(record) {
        let Status = record?.Status == "true" ? "false" : "true"
        let datas = {
            Identifier: record?.Identifier,
            Status: Status
        }
        axios
            .post(backurl + "/api/landing/change_status", datas).then(res => {
                console.log("change_status", res.data)
                if (res.data.status == "true") {
                    this.getData();
                    console.log("ressadfasdfa", res.data.message)
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }).catch((err) => { console.log("errrrrrrr", err) })
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <FormAddModal/>
                    {console.log("this.state.show", this.state.show)}
                    {/* <HomeUpdateModal record={this.state.currentRecord} onDismiss={() => {this.setState({ show : false })}}/> */}
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            {/* <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-form-modal"><FontAwesomeIcon icon={faPlus}/> Add Farm</button> */}
                            <h1 className="mt-2 text-primary">Subscriber</h1>
                            <div className="responsive-table">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Forms.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Forms);
