import Web3 from "web3";
import axios from "axios";
import { CHAIN_ID , CHAINS , CHAIN_INFO, TOKEN_CONTRACT, ERC20_ABI} from "../config/env";
import { LockedStaking, LockedStakingABI } from "../partials/Config";
import { Addblocklisthooks, Removeblocklisthooks } from "../../actions/blocklistaction";


export const addblocklistuser = async(address , account) => {
    try{console.log("add blocklistuser" , address , account);
        var rpc = CHAINS[CHAIN_ID]
        console.log("rpcurl" , rpc);
        const web3 = new Web3(window.ethereum);
        var wallet = web3.utils.toChecksumAddress(address); 
        const contract = new web3.eth.Contract(LockedStakingABI, LockedStaking);
        contract.methods.addBlacklistedUser(wallet).send({from : account}).then(async()=>{
            var payload = {walletaddress : wallet}
            var result = await Addblocklisthooks(payload);
            return result;
        });
    }
    catch(e){
        console.log("error on add blocklist user" , e);
    }
}

export const removeblocklistuser = async(address , account) => {
    try{console.log("Remove blocklist");
        var rpc = CHAINS[CHAIN_ID]
        console.log("rpcurl" , rpc);
        const web3 = new Web3(window.ethereum);
        var wallet = web3.utils.toChecksumAddress(address); 
        const contract = new web3.eth.Contract(ERC20_ABI, TOKEN_CONTRACT);
        contract.methods.removeBlacklist(wallet).send({from : account}).then(async()=>{
            var payload = {walletaddress : wallet}
            var result = await Removeblocklisthooks(payload);
            return result;
        });
       
    }
    catch(e){
        console.log("error on add blocklist user" , e);
    }
}

export const checkaddress = (wallet) => {
    try{
        var rpc = CHAINS[CHAIN_ID]
        const web3 = new Web3(rpc);
        const add = web3.utils.toChecksumAddress(wallet); console.log("adddd" , add);
        const value = web3.utils.isAddress(add);
        return value;
      }
      catch(e){
        // console.log("error on is vlidaddress" , e);
      }
}