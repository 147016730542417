import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from 'react';
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';
import User from "./components/pages/Users";

import Form from "./components/pages/Farms";
import Pool from "./components/pages/Pools";
import Ipaddess from "./components/pages/Ipaddess";
import Stake from "./components/pages/Stake";
import ManageSale from "./components/pages/ManageSale";
import Launchpad from "./components/pages/LaunchPad"
import About from "./components/pages/About";
import Home from './components/pages/Land'
import Landingcontent from './components/pages/Landimagecontent'
import Landingimage from './components/pages/Landimage';
import Landingterminal from './components/pages/Developterminal'
import Subscriber from "./components/pages/Subscriber";
import Setting from "./components/pages/Setting"
import News from "./components/pages/News";
import NewsDes from "./components/pages/Newsdes";
import ContactUs from "./components/pages/ContactUs";
import Artical from "./components/pages/Artical";
import ArticlesImage1 from "./components/pages/ArticleImage1";
import ArticlesImage2 from "./components/pages/ArticlesImage2";
import ArticlesImage3 from "./components/pages/ArticleImage3";
import ArticlesImage4 from "./components/pages/ArticleImage4";
import Blocklist from "./components/pages/blocklist";

if (localStorage.jwtToken) {
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = "./login";
    }
}

class App extends Component {
    render () {
        return (
            <Provider store={store}>
                <Router basename="">
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/login" component={Login} />
                            <Switch>
                                <PrivateRoute exact path="/Dashboard" component={Dashboard} />
                                <PrivateRoute exact path="/Users" component={User} />
                                <PrivateRoute exact path="/featruedes" component={Ipaddess} />
                                <PrivateRoute exact path="/Articles" component={Artical} />
                                <PrivateRoute exact path="/Feature" component={Pool} />
                                <PrivateRoute exact path="/Stake" component={Stake} />
                                <PrivateRoute exact path="/ManageSale/:id" component={ManageSale} />
                                <PrivateRoute exact path="/ManageSale" component={ManageSale} />
                                <PrivateRoute exact path="/LaunchPad" component={Launchpad} />
                                <PrivateRoute exact path="/About" component={About} />
                                <PrivateRoute exact path="/Home" component={Home} />
                                <PrivateRoute exact path="/landingcontent" component={Landingcontent} />
                                <PrivateRoute exact path="/landingimage" component={Landingimage} />
                                <PrivateRoute exact path="/landingterminal" component={Landingterminal} />
                                <PrivateRoute exact path='/Subscriber' component={Subscriber} />
                                <PrivateRoute exact path="/Setting" component={Setting} />
                                <PrivateRoute exact path="/News" component={News}/>
                                <PrivateRoute exact path="/Newsdescription" component={NewsDes}/>
                                <PrivateRoute exact path="/ContactUs" component={ContactUs}/>
                                <PrivateRoute exact path="/Articles1" component={ArticlesImage1}/>
                                <PrivateRoute exact path="/Articles2" component={ArticlesImage2}/>
                                <PrivateRoute exact path="/Articles3" component={ArticlesImage3}/>
                                <PrivateRoute exact path="/Articles4" component={ArticlesImage4}/>    
                                <PrivateRoute exact path="/blocklist" component={Blocklist} />                                         
                            </Switch>
                            <Route exact path="*" component={NotFound} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}
export default App;
