import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    IP_ADD,
    IP_UPDATE
} from "./types";

export const addIpaddress = (param, history) => dispatch => {
    var ipData = new FormData();
   
    ipData.append('whitelist',param.whitelist);
    

//console.log("formdata>>>>>>>",formData)   
    axios
        .post(backurl+"/api/ip-add", ipData)
        .then(res =>{   
           
            dispatch({
                type: IP_ADD,
                payload: res,
            })
        }
        ).catch(err =>{   
           
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
    }
    );
};

export const updateIP = (param) => dispatch => {
    var formUpdateData = new FormData();
    if (!isEmpty(param._id))   formUpdateData.append('_id',param._id );
   
   
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Description))formUpdateData.append('Description',param.Description );
    
    if (!isEmpty(param.file)) {
        formUpdateData.append('file',param.file );
       
    }
   
    axios
        .post(backurl+"/api/update/featuredes", formUpdateData)
        .then(res =>
            dispatch({
                type: IP_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updatenews = (param) => dispatch => {
    var formUpdateData = new FormData();
    if (!isEmpty(param._id))   formUpdateData.append('_id',param._id );
   
   
    if (!isEmpty(param.Heading)) formUpdateData.append('Heading',param.Heading );
    if (!isEmpty(param.Description))formUpdateData.append('Description',param.Description );
    
    if (!isEmpty(param.file)) {
        formUpdateData.append('file',param.file );
       
    }
   
    axios
        .post(backurl+"/api/update/news", formUpdateData)
        .then(res =>
            dispatch({
                type: IP_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};