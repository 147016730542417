import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatenews } from "../../actions/ipAction";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

class NewsUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
           
           
            Heading:this.props.record.Heading,
            Description: this.props.record.Description,
            errors: {},
            file:{},
        }
    }
    componentDidMount(props){
        console.log("data :",this.props.record)
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
               
               Heading:nextProps.record.Heading,
               Description: nextProps.record.Description,
               file: nextProps.record.file,
               
            })
           // console.log("data 2 : ",nextProps.record.quoteTokenSymbol)
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.form !== undefined
            && nextProps.auth.form.data !== undefined
            && nextProps.auth.form.data.message !== undefined
            && nextProps.auth.form.data.success) {
           
            toast(nextProps.auth.form.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
            $('#update-news-modal').modal('hide');
        
    }

    onFileChange = event => {
        var name = event.target.value;
           // Update the state
           this.setState({ file: event.target.files[0]});
           this.setState({ imagepath: name});
          
         };

        //  onFileChange = async(event) => {
        //     var name = event.target.value;
        //     this.setState({ file: event.target.files[0]});
        //     this.setState({ imagepath: name});
        //        var width;
        //        var height;
        //        var img = new Image()
        //        img.src = window.URL.createObjectURL(event.target.files[0])
        //        img.onload = () => {
        //         width = img.width;
        //         height = img.height;
          
               
            
        //        if ( Number(width) != Number(1600) && Number(height) != Number(675)){
        //         toast.error(`Image Height and width should be ${675} pixel and ${1600} pixel`,{

          
        //             style: {
        //             minWidth: '300px',
        //             minHeight: '55px'
        //             }
        //         }) 

        //         this.setState({ file: ""});
        //               this.setState({ imagepath: ""});
                 
        //       }
              
        //         sleep(2000) 
               
        
        //        }
        //      };
    onChange = e => {
       
        if (e.target.id === 'Heading') {
             this.setState({ Heading: e.target.value });
           }
           if (e.target.id === 'Description') {
               this.setState({ Description: e.target.value });
           }
          
   
           
   
       };
   
    onIPUpdate = e => {
        e.preventDefault();
        const updateIp = {
            _id: this.state.id,
           
            Heading:this.state.Heading,
            Description:this.state.Description,
            file: this.state.file
             };
        this.props.updatenews(updateIp);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-news-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update News</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onIPUpdate} id="update-ip">
                                    <input
                                        onChange={(e)=>this.onChange(e)}
                                        value={this.state.id}
                                        id="ip-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Heading</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.Heading}
                                                id="Heading"
                                                type="text"
                                                error={errors.Heading}
                                                className={classnames("form-control", {
                                                    invalid: errors.Heading
                                                })}/>
                                            <span className="text-danger">{errors.Heading}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="whitelist">Description</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.Description}
                                                id="Description"
                                                type="text"
                                                error={errors.Description}
                                                className={classnames("form-control", {
                                                    invalid: errors.Description
                                                })}/>
                                            <span className="text-danger">{errors.Description}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="logoURI">Update Image</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onFileChange}
                                                value={this.state.imagepath}
                                                error={errors.logoURI}
                                                id="logoURI"
                                                type="file"
                                                className={classnames("form-control", {
                                                    invalid: errors.logoURI
                                                })}
                                            />
                                            <span className="text-danger">{errors.logoURI}</span>
                                        </div>
                                    </div>
                         </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-ip"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NewsUpdateModal.propTypes = {
    updateIP: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updatenews }
)(withRouter(NewsUpdateModal));
