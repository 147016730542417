import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import {Form,InputGroup,Button } from 'react-bootstrap';
import logo from '../../Assets/images/logo.png';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {},
            show: false
        };
    }
     showPassword = (e) => {
        var eye = document.getElementById("pw_eye");
        var e = document.getElementById("password");
        
        if (e.type === "password") {
           
            this.setState({ show: true });
            e.type = "text";
            eye.className = "fa fa-eye";
        } else {
            this.setState({ show: false });
            e.type = "password";
            eye.className = "fa fa-eye-slash";
        }
    };
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        this.props.loginUser(userData);
    };


    
    render() {
        const { errors } = this.state;
        return (
            <div className="container">
                              
                <div className="row mt-5">

                    <div className="col-md-4 mx-auto mt-5 card shadow-lg loginsec">      
                                         <img className="loginlogo" src={logo} />             
                        <div className="card-body p-1">
                            <h2 className="text-center text-primary_green mt-2 logintitle">Login</h2>
                            <form noValidate onSubmit={this.onSubmit} className="white">
                                <label htmlFor="email">Email</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    error={errors.email}
                                    id="email"
                                    type="email"
                                    className={classnames("form-control", {
                                        invalid: errors.email
                                    })}
                                />
                                <span className="text-danger">{errors.email}</span>
                                <br/>
                                <label htmlFor="password">Password</label>
                                <InputGroup className="mb-3">
                                <input onChange={this.onChange} value={this.state.password} error={errors.password}
                                                                id="password" type="password" className={classnames("form-control", {
                                                                    invalid: errors.password
                                                                })}
                                                            />
                                    <InputGroup.Text >
                                    {!this.show ? <span  id="pw_eye" className="fa fa-eye" onClick={this.showPassword}></span>:
                                        <span id="pw_eye" className="fa fa-eye" onClick={this.showPassword}></span>}
                                    </InputGroup.Text>
                                </InputGroup>
                                <span className="text-danger">{errors.password}</span>
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn userbtn f-w mt-2 px-5">
                                        Login
                                    </button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Login);
