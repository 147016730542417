import axios from "axios";
import isEmpty from "is-empty";
import backurl from "../lib/config"
import {
    GET_ERRORS,
    LAUNCHPAD_ADD,
    LAUNCHPAD_UPDATE
} from "./types";

export const addLaunchpad = (param, history) => dispatch => {
    var LaunchPadData = new FormData();
    console.log("param.iscrpto",param.iscrpto);
    LaunchPadData.append('pid',param.pid);
    LaunchPadData.append('PriceTokencrypto',param.PriceTokencrypto);
    LaunchPadData.append('PriceToken',param.PriceToken );
    LaunchPadData.append('TokenAddress',param.TokenAddress);
    LaunchPadData.append('TokenName',param.TokenName);
    LaunchPadData.append('TokenSymbol',param.TokenSymbol);
    LaunchPadData.append('TokenDecimal',param.TokenDecimal);
    LaunchPadData.append('BNBMinimumContribution',param.BNBMinimumContribution );
    LaunchPadData.append('BNBMaximumContribution',param.BNBMaximumContribution );
    LaunchPadData.append('BUSDMinimumContribution',param.BUSDMinimumContribution );
    LaunchPadData.append('BUSDMaximumContribution',param.BUSDMaximumContribution );
    LaunchPadData.append('HardCap',param.HardCap);
    LaunchPadData.append('BUSDHardCap',param.BUSDHardCap);
    LaunchPadData.append('PresaleAddress',param.PresaleAddress);

   

//console.log("formdata>>>>>>>",formData)
    axios
        .post(backurl+"/api/LaunchPad-add", LaunchPadData)
        .then(res =>{   
            // console.log("yyyhyu>>",res)
            dispatch({
                type: LAUNCHPAD_ADD,
                payload: res,
            })
            // window.location.href = `/ManageSale/${this.state.PresaleAddress}`;
        }).catch(err =>{   
            // console.log("yyyhyu>>",err)
        dispatch({
            type: GET_ERRORS,
            payload:err.response.data
        })
    }
    );
};

export const updateLaunchpad = (param) => dispatch => {
    console.log("updatePool>>>>>",param)
    var LaunchPadUpdateData = new FormData();
    if (!isEmpty(param._id))   LaunchPadUpdateData.append('_id',param._id );
    if (!isEmpty(param.PriceToken)) LaunchPadUpdateData.append('PriceToken',param.PriceToken);
    if (!isEmpty(param.PriceTokencrypto)) LaunchPadUpdateData.append('PriceTokencrypto',param.PriceTokencrypto);
    
     if (!isEmpty(param.TokenAddress)) LaunchPadUpdateData.append('TokenAddress',param.TokenAddress);
    //  if (!isEmpty(param.TokenName)) LaunchPadUpdateData.append('TokenName',param.TokenName);
    // if (!isEmpty(param.TokenDecimal)) LaunchPadUpdateData.append('TokenDecimal',param.TokenDecimal);
    if (!isEmpty(param.MinimumContribution))LaunchPadUpdateData.append('MinimumContribution',param.MinimumContribution);
    if (!isEmpty(param.MaximumContribution))LaunchPadUpdateData.append('MaximumContribution',param.MaximumContribution);
    if (!isEmpty(param.NumberDaySale))LaunchPadUpdateData.append('NumberDaySale',param.NumberDaySale);
    if (!isEmpty(param.DepositeToken))LaunchPadUpdateData.append('DepositeToken',param.DepositeToken);
    if (!isEmpty(param.HardCap))LaunchPadUpdateData.append('HardCap',param.HardCap);
    if (!isEmpty(param.PresaleAddress))LaunchPadUpdateData.append('HardCap',param.PresaleAddress);
    // if (!isEmpty(param.WhiteList))LaunchPadUpdateData.append('WhiteList',param.WhiteList);
   
    if (!isEmpty(param.file)) {
        LaunchPadUpdateData.append('file',param.file );
        console.log('1111111',LaunchPadUpdateData);
    }
    else {
        console.log('222222222');
    }
    // console.log("LaunchPadUpdateData>>>>>",LaunchPadUpdateData)
    axios
        .post(backurl+"/api/LaunchPad-update", LaunchPadUpdateData)
        .then(res =>
            dispatch({
                type: LAUNCHPAD_UPDATE,
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
